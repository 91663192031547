$gt-color-main: $feature-color-dark;
$gt-color-main-lighter: lighten($gt-color-main, 20%);
$gt-color-btn: #ffffff;

#gitalk-container {
  * {
    color: $post-content-color-dark;
  }

  a {
    color: $gt-color-main !important;
    &:hover {
      color: $gt-color-main-lighter !important;
      border-color: $gt-color-main-lighter !important;
    }
  }

  .gt-svg svg {
    fill: $gt-color-main !important;
  }

  .gt-spinner::before {
    border-color: $gt-color-btn !important;
    border-top-color: $gt-color-main !important;
  }

  .gt-btn {
    background-color: $gt-color-main !important;
    border-color: $gt-color-main !important;
    color: $gt-color-btn !important;
    &-login {
      &:hover {
        background-color: $gt-color-main-lighter !important;
        border-color: $gt-color-main-lighter !important;
      }
    }
    &-preview {
      background-color: $gt-color-btn !important;
      color: $gt-color-main !important;
      &:hover {
        background-color: darken($gt-color-btn, 5%) !important;
        border-color: $gt-color-main-lighter !important;
      }
      .gt-btn-text {
        color: $gt-color-main !important;
      }
    }
    &-public {
      &:hover {
        background-color: $gt-color-main-lighter !important;
        border-color: $gt-color-main-lighter !important;
      }
    }
  }

  .gt-link {
    border-bottom-color: $gt-color-main !important;
  }

  .gt-user .is--poping .gt-ico svg {
    fill: $gt-color-main !important;
  }

  .gt-popup .gt-action.is--active:before {
    background: $gt-color-main !important;
  }

  .gt-header-controls-tip {
    color: $gt-color-main !important;
  }

  .gt-comment-username {
    color: $gt-color-main !important;
  }
}
